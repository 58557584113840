import React from "react"
import { Link } from "gatsby"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import PageTitle from "src/components/page-title"

import klubmodulImage from "src/images/case-study-images/case-study-klubmodul.png"

function Klubmodul() {
  return (
    <Layout>
      <SEO title="Klubmodul: Nickelled Case Studies" />
      <div class="bg-white overflow-hidden">
        <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
          <div class="mx-auto text-base max-w-prose lg:max-w-none">
            <h2 class="text-base text-oOrange font-semibold tracking-wide uppercase">
              Case Study
            </h2>
            <h1 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Case Study: Klubmodul
            </h1>
          </div>
          <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div class="relative lg:row-start-1 lg:col-start-2">
              <svg
                class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
                width="404"
                height="384"
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="384"
                  fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                />
              </svg>
              <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                    <img
                      class="rounded-lg shadow-lg object-cover object-center"
                      src={klubmodulImage}
                      alt="Klubmodul"
                      width="1184"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div class="mt-8 lg:mt-0">
              <div class="text-base max-w-prose mx-auto lg:max-w-none">
                <p class="text-lg text-gray-500">
                  Denmark-based Klubmodul is the leading name in European sport
                  club management. Founded in 2008, the company has grown
                  rapidly to become the virtual home of over 2000 sport clubs
                  and one million members, providing everything from
                  public-facing websites to subscription management and
                  bookkeeping for amateur sports clubs.
                </p>
                <p class="text-lg text-gray-500">
                  Using Klubmodul, administrators of clubs of all shapes and
                  sizes can painlessly manage time-intensive activities,
                  allowing them to focus on building, growing and enjoying great
                  communities.
                </p>
              </div>
              <div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <div class="ph6-ns pb6-ns">
                  <p>
                    In 2016, after setting a firm base in its native Denmark,
                    Klubmodul decided to launch a localised version of its
                    service in Germany. Due to the size of the market, founder
                    and CEO Jesper Weltström knew that the challenges of scaling
                    up the operations of Clubmodul.de would be very different to
                    his team’s experience of growing Klubmodul.dk:
                  </p>
                  <p>
                    “We foresaw immediately that a change in our approach would
                    be required to account for the larger market,” he explains.
                  </p>
                  <p>
                    “We wanted to continue to offer the same customer-centric
                    approach that helped us build Klubmodul into Denmark’s
                    market leader, without resorting to a labour-intensive
                    high-touch support model.”
                  </p>
                  <p>
                    In October 2016, Jesper approached Nickelled to provide a
                    high-quality introduction to Clubmodul.de which would help
                    new users in Germany to get the most out of the system.
                  </p>
                  <p>
                    “Within Clubmodul.de, we knew that there would be several
                    objectives which are critical for new users to master if
                    they’re going to gain value from the software.”
                  </p>
                  <h3>The challenge</h3>
                  <p>
                    The Klubmodul challenge was to ensure that new users could
                    get to these new tasks as quickly as possible, with little
                    intervention from the team. In an ideal state, the company
                    would be able to onboard new clubs without having to guide
                    or coach each individual organization on each step. Using
                    data from internal tools and their experience in Denmark,
                    Klubmodul identified several common activities such as:
                  </p>
                  <ul class="list-disc list-inside">
                    <li>Adding and managing teams or activities</li>
                    <li>Bookkeeping</li>
                    <li>
                      Adding new pages and editing existing pages in the content
                      management system (CMS)
                    </li>
                  </ul>
                  <p>
                    Using Nickelled, the team were able to make simple
                    step-by-step walkthroughs to show users how to complete each
                    of these key processes.
                  </p>
                  <p>
                    Each guide, which is easily accessible via a URL, can walk a
                    user through multiple pages, pointing at key areas of note
                    for users and offering more information at the beginning and
                    end of guides via a full-screen modal.
                  </p>
                  <p>
                    Furthermore, Nickelled’s point-and-click design meant that
                    the task of creating guides could be accomplished without
                    any code changes to the Clubmodul.de website — all guides
                    were created without the addition of a javascript snippet,
                    which greatly sped the time to launch for the guides.
                  </p>
                  <p>
                    It also allowed Nickelled to add functionality into other
                    parts of the organisation, such as a customer success
                    representative who needs to make a bespoke guide for a
                    specific process, or a sales rep who need to showcase a
                    particular feature for a prospect.
                  </p>
                  <p>
                    “We now have six team members with active accounts on the
                    Nickelled site, covering various business functions,” Jesper
                    explains. “This means that guides can be created by somebody
                    in product, support or customer success for entirely
                    different purposes, without any friction.”
                  </p>
                  <h3>The result</h3>
                  <p>
                    With Clubmodul.de now welcoming its first customers, the
                    team is now focused on building guides to preemptively
                    answer the most common questions posed by new users.
                  </p>
                  <p>
                    “Nickelled has helped us to make sure all of our new users
                    can get the most out of Clubmodul.de without intensive
                    hand-holding from the team - it’s an easy, scalable solution
                    which has helped to boost activation rates significantly.”
                  </p>
                  <p>
                    “Today an agent in the support team can handle 600 clubs.
                    That is just outstanding, just two years ago, an agent would
                    handle only 300 clubs!”
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Klubmodul
